/** Miert a Semmelweis script  */

jQuery(function ($) {

    //Carousel
    var owl1 = $(".post-slide .owl-carousel");
    if (!owl1.hasClass("ready")) {
        owl1.owlCarousel({
            center: true,
            items: 4,
            loop: true,
            margin: 10,
            dots: false,
            smartSpeed: 500,
            stagePadding: 50,
            nav: true,
            navText: [
                '<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Előző</span>',
                '<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Következő</span>',
            ],
            responsive: {
                0: {
                    items: 2,
                    nav: false
                },
                480: {
                    items: 4,
                    nav: true,
                    slideBy: 4
                },
                960: {
                    items: 5,
                    nav: true,
                    slideBy: 5
                }
            }
        });

        owl1.find(".card a").click(function (e) {
            e.preventDefault();
            var item = $(this).attr('class');
            owl1.find('.owl-item.center').removeClass('center');
            $(this).parent().parent().addClass('center');
            $(".post-slide .captions .caption").hide();
            $(".post-slide .captions").find("." + item).show();
            return false;
        });

        //Start
        var startitem = owl1.find('.owl-item.center .card a').attr('class');
        $(".post-slide .captions").find("." + startitem).show();

        //Show actual page
        owl1.on('changed.owl.carousel', function (event) {
            $(owl1.find('.owl-item .card a')[event.item.index]).trigger('click');
        });

        //Show on hover
        owl1.find('.owl-item .card a').hover(
                function () {
                    $(this).trigger('click');
                }
        );
        owl1.addClass("ready");
    }

    var owl1h = $(".post-slide-hirek .owl-carousel");
    if (!owl1h.hasClass("ready")) {
        owl1h.owlCarousel({
            center: true,
            items: 4,
            loop: true,
            margin: 10,
            dots: false,
            smartSpeed: 500,
            stagePadding: 50,
            nav: true,
            navText: [
                '<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Előző</span>',
                '<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Következő</span>',
            ],
            responsive: {
                0: {
                  items: 1,
                  nav: !1,
                  loop: !0,
                  rewind: !0

                },
                480: {
                  items: 2,
                  nav: !0,
                  slideBy: 1,
                  loop: !0,
                  rewind: !0,
                    startPosition: 1

                },
                960: {
                  items: 3,
                  nav: !0,
                  slideBy: 1,
                  loop: !0,
                  rewind: !0,
                  startPosition: 1

                }
            }
        });

        owl1h.find(".card a").click(function (e) {
            e.preventDefault();
            var itemH = $(this).attr('class');
            owl1h.find('.owl-item.center').removeClass('center');
            $(this).parent().parent().addClass('center');
            $(".post-slide .captions .caption").hide();
            $(".post-slide .captions").find("." + itemH).show();
            return false;
        });

        //Start
        var startitemH = owl1h.find('.owl-item.center .card a').attr('class');

        $(".post-slide .captions").find("." + startitemH).show();

        //Show actual page
        owl1h.on('changed.owl.carousel', function (event) {
            $(owl1h.find('.owl-item .card a')[event.item.index]).trigger('click');
        });

        //Show on hover
        owl1h.find('.owl-item .card a').hover(
                function () {
                    $(this).trigger('click');
                }
        );
        owl1h.addClass("ready");
    }

    //Video
    $(".yt-slider .owl-carousel").each(function () {
        $(this).owlCarousel({
            center: true,
            items: 2,
            loop: true,
            margin: 10,
            dots: false,
            smartSpeed: 1000,
            nav: true,
            navText: [
                '<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Előző</span>',
                '<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Következő</span>',
            ],
            responsive: {
                0: {
                    items: 1,
                    nav: false,
                    stagePadding: 30,
                },
                480: {
                    items: 2,
                    nav: true
                }
            }
        });

    });

    $(".yt-slider2 .owl-carousel").each(function () {
      $(this).owlCarousel({
          center: true,
          items: 4,
          loop: true,
          margin: 10,
          dots: false,
          smartSpeed: 1000,
          nav: true,
          navText: [
              '<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Előző</span>',
              '<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Következő</span>',
          ],
          responsive: {
            0: {
                items: 1,
                nav: !1,
                stagePadding: 30
            },
            720: {
                items: 2,
                nav: true
            },
            960: {
                items: 3,
                nav: true
            },
            1200: {
                items: 4,
                nav: true
            }
        }
  });

  });

    $(".videolink").each(function () {
        if (!$(this).hasClass("ready")) {
            $(this).addClass("ready");
            $(this).click(function (e) {
                e.preventDefault();
                var current_carousel = $(this).parents(".owl-carousel");
                var item;
                var center_index = 0;
                var src = $(this).attr('data-src');
                if (current_carousel.length > 0) {
                    center_index = current_carousel.find('.owl-item.center').index();
                    item = $(this).parents(".owl-item");
                    if (item.hasClass("center")) {
                        showVideo(src);
                    } else if (item.index() > center_index) {
                        current_carousel.trigger('next.owl.carousel');
                    } else if (item.index() < center_index) {
                        current_carousel.trigger('prev.owl.carousel');
                    }
                } else {
                    showVideo(src);
                }
                return false;
            });
        }

    });




});
